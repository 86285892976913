import React, { Component } from 'react';

class CookieBanner extends Component {
    componentDidMount() {
        if (this.props.cookies && !this.props.cookies.get('cookieCompliance')) {
            document.querySelector('.cookie-banner').classList.add('cookie-banner--show');
        }
    }

    render() {
        const setCookie = (e) => {
            e.preventDefault();

            e.target.closest('.cookie-banner').classList.add('cookie-banner--hide');
            document.querySelector('#site-wrapper').classList.remove('cookie-active');

            this.props.cookies.set('cookieCompliance', true, { path: '/' });
        },
        hideCookieBanner = (e) => {
            e.preventDefault();
            e.target.closest('.cookie-banner').classList.add('cookie-banner--hide');
            document.querySelector('#site-wrapper').classList.remove('cookie-active');
        };
        
        return (
            <div className="cookie-banner">
                <div className="wrapper">
                    <p>We use cookies on our website to give you the most relevant experience. By clicking "Accept", you consent to the use of all the cookies in accordance with our <a href="https://themysteryagency.com/cookie-policy" target="_blank" rel="noopener noreferrer">Cookie Policy</a></p>
                    <button className="button" onClick={setCookie}>Accept</button>
                    <button className="button" onClick={hideCookieBanner}>Close</button>
                </div>
            </div>
        );
    }
}

export default CookieBanner;