import React, { Component } from "react";
import PropTypes from "prop-types";
import Header from "../components/header";
import CookieBanner from "../components/shared/cookie";
import Cookies from 'universal-cookie';

import "../assets/styles/reboot.min.css";
import "../assets/styles/global-styles.css";

const cookies = new Cookies();

class DefaultLayout extends Component {
  render() {

    return (
      <div id="site-wrapper" className={`${cookies.get('cookieCompliance') === 'true' ? '' : 'cookie-active'}`}>
        <Header />
        <main id="site-content" role="main">
          {this.props.children}
        </main>
        <CookieBanner cookies={cookies} />
      </div>
    );
  }
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DefaultLayout;
